import React from 'react';

import Layout from '../components/layout';

const Error404Page = () => (
  <Layout>
    <h1>Not Found</h1>
  </Layout>
);

export default Error404Page;
